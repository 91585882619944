import "@orestbida/iframemanager/dist/iframemanager.js"
import "@orestbida/iframemanager/dist/iframemanager.css"
import * as cc from "vanilla-cookieconsent"
import "vanilla-cookieconsent/dist/cookieconsent.css"

const im = iframemanager()

im.run({
    currLang: "de",
    onChange: ({ changedServices, eventSource }) => {
        if (eventSource.type === 'click') {
        const servicesToAccept = [
            ...cc.getUserPreferences().acceptedServices['analytics'],
            ...changedServices,
        ];

        cc.acceptService(servicesToAccept, 'analytics');
        }
    },
    services: {
        youtube: {
        embedUrl: "https://www.youtube-nocookie.com/embed/{data-id}",
        thumbnailUrl: "https://i3.ytimg.com/vi/{data-id}/hqdefault.jpg",
        iframe: {
            allow:
            "accelerometer; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
        },
        cookie: {
            name: "cc_youtube"
        },
        languages: {
            de: {
            notice:
                "Dieser Inhalt wird von einem externen Anbieter gehostet. Durch die Anzeige des externen Inhalts akzeptieren Sie die <a rel=\"noreferrer\" href=\"https://www.youtube.com/t/terms\" title=\"Bedingungen und Konditionen\" target=\"_blank\">Bedingungen und Konditionen</a> von youtube.com.",
            loadBtn: "Video laden",
            loadAllBtn: "Nicht nochmal fragen"
            }
        }
        },
        vimeo: {
        embedUrl: "https://player.vimeo.com/video/{data-id}",
        thumbnailUrl: async (dataId, setThumbnail) => {
            const url = `https://vimeo.com/api/v2/video/${dataId}.json`
            const response = await (await fetch(url)).json()
            const thumbnailUrl = response[0]?.thumbnail_large
            thumbnailUrl && setThumbnail(thumbnailUrl)
        },
        iframe: {
            allow: "fullscreen; picture-in-picture, allowfullscreen;"
        },
        cookie: {
            name: "cc_vimeo"
        },
        languages: {
            de: {
            notice:
                "Dieser Inhalt wird von einem externen Anbieter gehostet. Durch die Anzeige des externen Inhalts akzeptieren Sie die <a rel=\"noreferrer noopener\" href=\"https://vimeo.com/terms\" target=\"_blank\">Bedingungen und Konditionen</a> von vimeo.com.",
            loadBtn: "Video laden",
            loadAllBtn: "Nicht nochmal fragen"
            }
        }
        },
        matterport: {
        embedUrl: "https://my.matterport.com/show/?m={data-id}",
        thumbnailUrl:
            "https://my.matterport.com/api/v1/player/models/{data-id}/thumb",
        iframe: {
            allow:
            "fullscreen; picture-in-picture; xr-spatial-tracking; allowfullscreen;"
        },
        cookie: {
            name: "cc_matterport"
        },
        languages: {
            de: {
            notice:
                "Dieser Inhalt wird von einem externen Anbieter gehostet. Durch die Anzeige des externen Inhalts akzeptieren Sie die <a rel=\"noreferrer noopener\" href=\"https://matterport.com/terms-of-use\" target=\"_blank\">Bedingungen und Konditionen</a> von matterport.com.",
            loadBtn: "Rundgang laden",
            loadAllBtn: "Nicht nochmal fragen"
            }
        }
        }
    }
})

cc.run({
    revision: 1.2,
    categories: {
        necessary: {
            enabled: true,
            readOnly: true,
        },
        analytics: {
            readOnly: false,
            enabled: false,
        },
        youtube: {
            readOnly: false,
            enabled: false,
        },
        vimeo: {
            readOnly: false,
            enabled: false,
        },
        instagram: {
            readOnly: false,
            enabled: false,
        },
        elfsight: {
            readOnly: false,
            enabled: false,
        },
        holidaycheck: {
            readOnly: false,
            enabled: false,
        }
    },
    guiOptions: {
        consentModal: {
            layout: 'box wide',
            position: 'middle center'
        }
    },
    onConsent: ({cookie}) => {
        if (cookie.categories.includes("youtube")) { im.acceptService("youtube") }
        if (cookie.categories.includes("vimeo")) { im.acceptService("vimeo") }
    },
    onChange: ({cookie}) => {
        cookie.categories.includes("youtube") ? im.acceptService("youtube") : im.rejectService("youtube")
        cookie.categories.includes("vimeo") ? im.acceptService("vimeo") : im.rejectService("vimeo")
    },
    language: {
        default: 'de',
        translations: {
            de: {
                consentModal: {
                    title: 'Wir nutzen Cookies 🍪',
                    description: 'Um unsere Webseite für Sie optimal zu gestalten und fortlaufend zu verbessern sowie zur interessengerechten Ausspielung von Inhalten, verwenden wir Cookies. Durch Bestätigen des Buttons "Alle akzeptieren" stimmen Sie der Verwendung zu. Über den Link "Individuelle Einstellungen" können Sie auswählen, welche Cookies Sie zulassen wollen.',
                    acceptAllBtn: 'Alle akzeptieren',
                    acceptNecessaryBtn: 'Alle ablehnen',
                    showPreferencesBtn: 'Individuelle Einstellungen'
                },
                preferencesModal: {
                    title: 'Cookie Einstellungen',
                    acceptAllBtn: 'Alle akzeptieren',
                    acceptNecessaryBtn: 'Alle ablehnen',
                    savePreferencesBtn: 'Einstellungen speichern',
                    closeIconLabel: 'Modal schliessen',
                    serviceCounterLabel: 'Service|Dienste',
                    sections: [
                        {
                            title: 'Cookie Nutzung',
                            description: 'Hier können Sie einsehen und anpassen, welche Information wir über Sie speichern. Um mehr zu erfahren, lesen Sie bitte unsere Datenschutzerklärung.'
                        },
                        {
                            title: 'Technisch notwendige Cookies',
                            description: 'Technisch notwendige Cookies sind Cookies, die für die ordnungsgemäße Funktion einer Website erforderlich sind. Sie ermöglichen grundlegende Funktionen wie die Navigation auf der Website, den Zugriff auf geschützte Bereiche und die Nutzung von Formularen.',
                            linkedCategory: 'necessary'
                        },
                        {
                            title: "Google Analytics",
                            description: "Google Analytics setzt Cookies ein, um Informationen wie die Anzahl der Besucher, die Dauer des Website-Besuchs und die verwendeten Geräte zu erfassen. Diese Daten können dazu beitragen, die Benutzererfahrung zu verbessern und das Angebot auf unsere Zielgruppe anzupassen.",
                            linkedCategory: 'analytics'
                        },
                        {
                            title: 'Youtube',
                            description: 'Youtube verwendet Cookies, um die Video-Wiedergabe und Interaktion mit dem Video-Player zu ermöglichen und erfasst dabei auch personenbezogene Daten wie IP-Adressen.',
                            linkedCategory: 'youtube'
                        },
                        {
                            title: 'Vimeo',
                            description: 'Vimeo verwendet Cookies, um die Video-Wiedergabe und Interaktion mit dem Video-Player zu ermöglichen und erfasst dabei auch personenbezogene Daten wie IP-Adressen.',
                            linkedCategory: 'vimeo'
                        },
                        {
                            title: 'Instagram',
                            description: 'Externes Widget um Instagram Inhalte anzuzeigen. Es werden vom Anbieter Seitenaufrufe zu Statistikzwecken erfasst.',
                            linkedCategory: 'instagram'
                        },
                        {
                            title: 'Elfsight',
                            description: 'Externes Widget um Google Bewertungen anzuzeigen. Es werden vom Anbieter Seitenaufrufe zu Statistikzwecken erfasst.',
                            linkedCategory: 'elfsight'
                        },
                        {
                            title: 'HolidayCheck',
                            description: 'Externes Widget um HolidayCheck Bewertungen anzuzeigen. Es werden vom Anbieter Seitenaufrufe zu Statistikzwecken erfasst.',
                            linkedCategory: 'holidaycheck'
                        },
                        {
                            title: 'Weitere Informationen',
                            description: 'Um mehr zu erfahren, lesen Sie bitte unsere <a class=\"cc-link\" href=\"/datenschutz\">Datenschutzerklärung</a>.'
                        }
                    ]
                },
            }
        }
    }
});