import Splide from '@splidejs/splide'
import '@splidejs/splide/css'

document.addEventListener('DOMContentLoaded', function(){
    const heroSlider = document.querySelector('[data-hero-slider]')

    if(heroSlider) {
        new Splide('.splide', {
            type: 'fade',
            rewind: true,
            arrows: false,
            pagination: false,
            autoplay: true,
            interval: 5000,
            speed: 1000
        }).mount()
    }

    const imageSliders = document.querySelectorAll('[data-image-slider]')

    if(imageSliders) {
        imageSliders.forEach(carousel => new Splide(carousel, {
            pagination: true,
            arrows: false,
            perPage: 2,
            gap: '.5rem',
            autoWidth: true,
            classes: {
                pagination: 'splide__pagination -bottom-8',
                page: 'splide__pagination__page w-3 h-3 bg-secondary-950 mx-1',
          },
        }).mount())
    }

    const fewoCardSliders = document.querySelectorAll('[data-fewo-card-slider]')

    if(fewoCardSliders) {
        fewoCardSliders.forEach(carousel => new Splide(carousel, {
            pagination: true,
            arrows: false,
            perPage: 1,
            classes: {
                pagination: 'splide__pagination',
                page: 'splide__pagination__page w-3 h-3 mx-1',
          },
        }).mount())
    }
})
