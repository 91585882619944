import Alpine from "alpinejs"
import collapse from '@alpinejs/collapse'
import './splide'
import './cmp'
import './flatpickr'
import '/src/css/main.css'

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR")
  });
}

Alpine.plugin(collapse)
window.Alpine = Alpine

document.addEventListener("alpine:init", () => {
  Alpine.data("infoPopup", () => {
    return {
      isHidden: false,

      hidePopup() {
        this.isHidden = true;
        document.cookie = "hidePopup=true;path=/;expires=; SameSite=Lax"
      },

      checkCookie() {
        const cookies = document.cookie.split("; ");
        for (let cookie of cookies) {
          let [name, value] = cookie.split("=");
          if (name === "hidePopup" && value === "true") {
            this.isHidden = true;
          }
        }
      },

      init() {
        this.checkCookie()
      }
    }
  })
})

Alpine.start()

