import flatpickr from "flatpickr"
import { German } from "flatpickr/dist/l10n/de"

document.addEventListener('DOMContentLoaded', function() {
    const dateRangePicker = document.querySelector("[data-date-range]")

    if(!dateRangePicker) return

    flatpickr(dateRangePicker, {
        "locale": German,
        "mode": "range",
        "minDate": new Date().toISOString().split("T")[0],
        "dateFormat": "d.m.Y"
    })
})